<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import campOverviewDashboard from "./components/campDashboard/overview";
import campHostingDashboard from "./components/campDashboard/hosting";
import campGsDashboard from "./components/campDashboard/greens";
import campOpsDashboard from "./components/campDashboard/ops";
import campCatDashboard from "./components/campDashboard/catering";
import campReqDashboard from "./components/campDashboard/request";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    campOverviewDashboard,
    campCatDashboard,
    campGsDashboard,
    campOpsDashboard,
    campHostingDashboard,
    campReqDashboard
  },

  mounted() {
    this.campUid = this.$route.params.campId;
    this.fetchCampDashboard();
  },

  methods: {
    fetchCampDashboard() {
      var loader = this.$loading.show();
      this.$http
        .post("/reporting/campDashboard", {
          campId: this.campUid,
        })
        .then((res) => {
          loader.hide();
          // console.log(res);
          res.data.DashboardDataHeader ? this.DataDashboard = res.data.DashboardDataHeader : this.$toast.warning(res.data.msg);
          this.header = true;
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {
          loader.hide();
        });
    },
    tabsPrest(prest){
      switch (prest) {
        case 'green_space':
          this.gsShow = !this.gsShow
          break;
      
        default:
          break;
      }
    }
  },

  data() {
    return {
      title: "",
      header:false,
      campUid: "",
      DataDashboard:"",
      gsShow:false,
      opsShow:false,
      catShow:false,
      hostShow:false,
      reqShow:false,
      base: {
        allowedCamps: [],
      },
      items: [
        {
          text: "",
          href: "/",
        },
      ],
      icons:[
        {key:'request',name:'Requêtes',link:'@/assets/images/dashboard/idea.png'},
        {key:'hosting',name:'Hébergement',link:'@/assets/images/dashboard/room.png'},
        {key:'catering',name:'Restauration',link:'@/assets/images/dashboard/catering.png'},
        {key:'green_space',name:'Espaces Vert',link:'@/assets/images/dashboard/gs.png'},
        {key:'3D_Ops',name:'Ops 3D',link:'@/assets/images/dashboard/extermination.png'},
      ]
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <campOverviewDashboard v-if="header" :dataHeader="DataDashboard" />
    <hr />
    <div class="row p-4 text-center d-flex justify-content-between">
      
        <!-- <div class="col" v-for="(icon,i) in icons" :key="i">
          <img
            @click="tabsPrest(icon.key)"
            style="cursor:pointer"
            class="card-img img-fluid small-img"
            :src="require(`${icon.link}`)"
          />
          <h3>{{icon.name}}</h3>
        </div> -->
      
      
        <div class="col">
          <img
          @click="reqShow=!reqShow"
            style="cursor:pointer"
            class="card-img img-fluid small-img"
            :src="require('@/assets/images/dashboard/idea.png')"
          />
          <h3>Requêtes</h3>
        </div>

        <div class="col">
          <img
           @click="hostShow=!hostShow"
            style="cursor:pointer"
            class="card-img img-fluid small-img"
            :src="require('@/assets/images/dashboard/room.png')"
          />
          <h3>Hébergement</h3>
        </div>
      
      
        <div class="col">
          <img
            @click="catShow=!catShow"
            style="cursor:pointer"
            class="card-img img-fluid small-img"
            :src="require('@/assets/images/dashboard/catering.png')"
          />
          <h3>Restauration</h3>
        </div>
      
      
        <div class="col">
          <img
            @click="gsShow=!gsShow"
            style="cursor:pointer"
            class="card-img img-fluid small-img"
            :src="require('@/assets/images/dashboard/gs.png')"
          />
          <h3>Espace Vert</h3>
        </div>
      
      
        <div class="col">
          <img
           @click="opsShow=!opsShow"
            style="cursor:pointer"
            class="card-img img-fluid small-img"
            :src="require('@/assets/images/dashboard/extermination.png')"
          />
          <h3>Ops 3D</h3>
        </div>
      
    </div>
    <!-- <campHostingDashboard/> -->
    <!-- <campGsDashboard :campid="$route.params.campId"/> -->
    <campGsDashboard v-if="gsShow == true" :campid="$route.params.campId"/>
    <campOpsDashboard v-if="opsShow == true" :campid="$route.params.campId"/>
    <campCatDashboard v-if="catShow == true" :campid="$route.params.campId"/>
    <campHostingDashboard v-if="hostShow == true" :campid="$route.params.campId"/>
    <campReqDashboard v-if="reqShow == true" :campid="$route.params.campId"/>
  </Layout>
</template>

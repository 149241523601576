<template>
  <div>
    <h2>Requêtes</h2>

    <div class="row mb-4">
      <div class="col-md-3">
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <label for="">Date</label>
                    <VueCtkDateTimePicker
                      locale="fr"
                      style="display:inline-block;margin-bottom: 20px;"
                      :range="true"
                      color="#34495e"
                      :custom-shortcuts="customDateRangeShortcuts"
                      :only-date="true"
                      :auto-close="false"
                    ></VueCtkDateTimePicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 mt-2">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      ##############
                    </h5>
                    <span class="h2 font-weight-bold mb-0">350,897</span>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-success mr-2"
                    ><i class="fa fa-arrow-up"></i> 3.48%</span
                  >
                  <span class="text-nowrap">Since last month</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card card-stats mb-4 mb-xl-0 f-h text-center">
          <div class="card-body">
            <div class="row">
              <div class="col br-solid">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Demandes Non Traité
                </h5>
                <span class="h2 font-weight-bold mb-0 text-danger">{{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 4 }).format(DashboardData.diff > 0 ?  100 - DashboardData.diff : 0) }} %</span>
              </div>
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Demande Traité
                </h5>
                <span class="h2 font-weight-bold mb-0 text-success"
                  >{{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(DashboardData.diff) }} %</span
                >
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Maintenance
                </h5>
                <h4 class="mt-4">{{DashboardData.maintenance}}</h4>
              </div>
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Prise en Charge
                </h5>
                <h4 class="mt-4">{{DashboardData.take_away}}</h4>
              </div>
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Dotations
                </h5>
                <h4 class="mt-4">{{DashboardData.dotation}}</h4>
              </div>
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Repas à Emporter
                </h5>
                <h4 class="mt-4">{{DashboardData.visitor_support}}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="row">
          <div class="col-xl-12 col-lg-12 mt-2">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      ODS En cours
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{DashboardData.inprogress}}</span>
                  </div>
                </div>
                <!-- <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-success mr-2"
                    ><i class="fa fa-arrow-up"></i> 3.48%</span
                  >
                  <span class="text-nowrap">Since last month</span>
                </p> -->
              </div>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 mt-2">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      ODS Clôturés
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{DashboardData.finished}}</span>
                  </div>
                </div>
                <!-- <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-success mr-2"
                    ><i class="fa fa-arrow-up"></i> 3.48%</span
                  >
                  <span class="text-nowrap">Since last month</span>
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row mt-4 mb-4">
      <div class="col-md-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Réservations /type de chambre
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Réservations /structure
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div id="chart" class="card p-2">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  props:['campid'],
  data() {
    return {
      dates:{},
      DashboardData:[],
      customDateRangeShortcuts: [
        { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
        { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
        { key: "last7Days", label: "7 derniers jours", value: 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label: "Ce mois", value: "month" },
        { key: "lastMonth", label: "Dernier mois", value: "-month" },
        { key: "thisYear", label: "Cette année", value: "year" },
        { key: "lastYear", label: "L'année dernière", value: "-year" },
      ],
      series:[],
        chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'],
            },
            yaxis: {
              title: {
                text: 'Nombres de demandes'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  // return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(val)
                  return val
                }
              }
            }
          },
    };
  },
  mounted(){
      this.fetchCampDashboard();
  },
  methods:{
      fetchCampDashboard() {
        var loader = this.$loading.show();
        this.$http.post("/reporting/campDashboardByType", {
            campId: this.campid,
            type:'request',
            dates:this.dates.start ? this.dates : null
        })
        .then((res) => {
            loader.hide();
            console.log(res);
            this.DashboardData = res.data.DashboardData;
            this.series = [
                {name:'Maintenance',data:res.data.DashboardData.yearData.maintenances},
                {name:'Prise en Charge',data:res.data.DashboardData.yearData.take_aways},
                {name:'Dotation',data:res.data.DashboardData.yearData.dotations},
                {name:'Repas à Emporter',data:res.data.DashboardData.yearData.visitor_supports},
              ]
        })
        .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
        })
        .finally(() => {
            loader.hide();
        });
    },
  }
};
</script>

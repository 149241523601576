<template>
  <div>
    <h2>Opération 3D</h2>

    <div class="row mb-4">
      <div class="col-md-3">
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <label for="">Date</label>
                    <VueCtkDateTimePicker
                      locale="fr"
                      style="display:inline-block;margin-bottom: 20px;"
                      :range="true"
                      formatted="ll"
                      color="#34495e"
                      :custom-shortcuts="customDateRangeShortcuts"
                      :only-date="true"
                      :auto-close="false"
                      v-model="dates"
                    ></VueCtkDateTimePicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-xl-12 col-lg-12 mt-2">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Nombre d'Absences
                    </h5>
                    <span class="h2 font-weight-bold mb-0">
                      {{DashboardData.abs}}
                    </span>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-muted text-sm">
                  
                  <span class="text-nowrap">Depuis le dernier mois</span>
                </p>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="col-md-6">
        <div class="card card-stats mb-4 mb-xl-0 f-h text-center">
          <div class="card-body">
            <div class="row">
              <div class="col br-solid">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Coût Mensuel Prévisionnel
                </h5>
                <span class="h4 font-weight-bold mb-0 text-danger">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD',maximumSignificantDigits: 2 }).format(DashboardData.monthCost)}}</span>
              </div>
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Coût Mensuel Vérifié
                </h5>
                <span class="h4 font-weight-bold mb-0 text-success">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD',maximumSignificantDigits: 2 }).format(DashboardData.monthCost)}}</span>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Total Services Demandés
                </h5>
                <h4 class="mt-4">{{DashboardData.services}}</h4>
              </div>
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Total Fournitures Demandés
                </h5>
                <h4 class="mt-4">{{DashboardData.products}}</h4>
              </div>
              <!-- <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Total Produits
                </h5>
                <h4 class="mt-4">{{DashboardData.products}}</h4>
              </div> -->
              <!-- <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Réalisation
                </h5>
                <h4 class="mt-4">{{DashboardData.realisation}}</h4>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="row">
          <div class="col-xl-12 col-lg-12 mt-2">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      ODS En cours
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{DashboardData.inprogress}}</span>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-success mr-2"
                    ><i class="fa fa-arrow-up"></i> 3.48%</span
                  >
                  <span class="text-nowrap">Since last month</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 mt-2">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      ODS Clôturés
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{DashboardData.finished}}</span>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-success mr-2"
                    ><i class="fa fa-arrow-up"></i> 3.48%</span
                  >
                  <span class="text-nowrap">Since last month</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="chart" class="card p-2">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
    
  </div>
</template>

<script>
export default {
    props:['campid'],
    name:'campGsDashboard',
  data() {
    return {
        dates:{},
        DashboardData:[],
        customDateRangeShortcuts: [
            { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
            { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
            { key: "last7Days", label: "7 derniers jours", value: 7 },
            { key: "last30Days", label: "30 derniers jours", value: 30 },
            { key: "thisMonth", label: "Ce mois", value: "month" },
            { key: "lastMonth", label: "Dernier mois", value: "-month" },
            { key: "thisYear", label: "Cette année", value: "year" },
            { key: "lastYear", label: "L'année dernière", value: "-year" },
        ],
        series:[],
        chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'],
            },
            yaxis: {
              title: {
                text: 'Coût Vérifié (DZD)'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD',maximumSignificantDigits: 2 }).format(val)
                }
              }
            }
          },
    };
  },
  mounted(){
      this.fetchCampDashboard();
  },
  methods:{
      fetchCampDashboard() {
        var loader = this.$loading.show();
        this.$http.post("/reporting/campDashboardByType", {
            campId: this.campid,
            type:'threed_ops',
            dates:this.dates.start ? this.dates : null
        })
        .then((res) => {
            loader.hide();
            // console.log(res);
            if(res.data.DashboardData){
            this.DashboardData = res.data.DashboardData;
            this.series = [
                {name:'Acquisition Fournitures',data:res.data.DashboardData.yearData.equipments},
                {name:'Prestation de Services',data:res.data.DashboardData.yearData.services},
              ]
            }
            else this.$toast.error(res.data.msg);
        })
        .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
        })
        .finally(() => {
            loader.hide();
        });
    },
  }
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Hébergement")]),
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-md-3" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-12 col-lg-12" }, [
            _c("div", { staticClass: "card card-stats mb-4 mb-xl-0" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Date")]),
                      _c("VueCtkDateTimePicker", {
                        staticStyle: {
                          display: "inline-block",
                          "margin-bottom": "20px"
                        },
                        attrs: {
                          locale: "fr",
                          range: true,
                          color: "#34495e",
                          "custom-shortcuts": _vm.customDateRangeShortcuts,
                          "only-date": true,
                          "auto-close": false
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ]),
          _vm._m(0)
        ])
      ]),
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "div",
          { staticClass: "card card-stats mb-4 mb-xl-0 f-h text-center" },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col br-solid" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "card-title text-uppercase text-muted mb-0"
                    },
                    [_vm._v(" Taux d'occupation ")]
                  ),
                  _c(
                    "span",
                    { staticClass: "h2 font-weight-bold mb-0 text-danger" },
                    [
                      _vm._v(
                        _vm._s(
                          new Intl.NumberFormat("en-IN", {
                            maximumSignificantDigits: 2
                          }).format(_vm.DashboardData.occuped)
                        ) + "%"
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "card-title text-uppercase text-muted mb-0"
                    },
                    [_vm._v(" Taux de disponibilité ")]
                  ),
                  _c(
                    "span",
                    { staticClass: "h2 font-weight-bold mb-0 text-success" },
                    [
                      _vm._v(
                        _vm._s(
                          (100 - _vm.DashboardData.occuped).toPrecision(4)
                        ) + "%"
                      )
                    ]
                  )
                ])
              ]),
              _c("hr"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "card-title text-uppercase text-muted mb-0"
                    },
                    [_vm._v(" Total Chambres ")]
                  ),
                  _c("h4", { staticClass: "mt-4" }, [
                    _vm._v(_vm._s(_vm.DashboardData.rooms))
                  ])
                ]),
                _vm._m(1),
                _vm._m(2),
                _vm._m(3)
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "col-md-3" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-12 col-lg-12 mt-2" }, [
            _c("div", { staticClass: "card card-stats mb-4 mb-xl-0" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "h5",
                      {
                        staticClass: "card-title text-uppercase text-muted mb-0"
                      },
                      [_vm._v(" ODS En cours ")]
                    ),
                    _c("span", { staticClass: "h2 font-weight-bold mb-0" }, [
                      _vm._v(_vm._s(_vm.DashboardData.inprogress))
                    ])
                  ])
                ]),
                _vm._m(4)
              ])
            ])
          ]),
          _c("div", { staticClass: "col-xl-12 col-lg-12 mt-2" }, [
            _c("div", { staticClass: "card card-stats mb-4 mb-xl-0" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "h5",
                      {
                        staticClass: "card-title text-uppercase text-muted mb-0"
                      },
                      [_vm._v(" ODS Clôturés ")]
                    ),
                    _c("span", { staticClass: "h2 font-weight-bold mb-0" }, [
                      _vm._v(_vm._s(_vm.DashboardData.finished))
                    ])
                  ])
                ]),
                _vm._m(5)
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._m(6),
    _c(
      "div",
      { staticClass: "card p-2", attrs: { id: "chart" } },
      [
        _c("apexchart", {
          attrs: {
            type: "area",
            height: "350",
            options: _vm.chartOptions,
            series: _vm.series
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-12 col-lg-12 mt-2" }, [
      _c("div", { staticClass: "card card-stats mb-4 mb-xl-0" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "h5",
                { staticClass: "card-title text-uppercase text-muted mb-0" },
                [_vm._v(" Arrivées prévue demain ")]
              ),
              _c("span", { staticClass: "h2 font-weight-bold mb-0" }, [
                _vm._v("350")
              ])
            ])
          ]),
          _c("p", { staticClass: "mt-3 mb-0 text-muted text-sm" }, [
            _c("span", { staticClass: "text-success mr-2" }, [
              _c("i", { staticClass: "fa fa-arrow-up" }),
              _vm._v(" 3.48%")
            ]),
            _c("span", { staticClass: "text-nowrap" }, [
              _vm._v("Since last month")
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h5", { staticClass: "card-title text-uppercase text-muted mb-0" }, [
        _vm._v(" Disponibles ")
      ]),
      _c("h4", { staticClass: "mt-4" }, [_vm._v("356")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h5", { staticClass: "card-title text-uppercase text-muted mb-0" }, [
        _vm._v(" Occupées ")
      ]),
      _c("h4", { staticClass: "mt-4" }, [_vm._v("120")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h5", { staticClass: "card-title text-uppercase text-muted mb-0" }, [
        _vm._v(" En maintenance ")
      ]),
      _c("h4", { staticClass: "mt-4" }, [_vm._v("2")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-3 mb-0 text-muted text-sm" }, [
      _c("span", { staticClass: "text-success mr-2" }, [
        _c("i", { staticClass: "fa fa-arrow-up" }),
        _vm._v(" 3.48%")
      ]),
      _c("span", { staticClass: "text-nowrap" }, [_vm._v("Since last month")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-3 mb-0 text-muted text-sm" }, [
      _c("span", { staticClass: "text-success mr-2" }, [
        _c("i", { staticClass: "fa fa-arrow-up" }),
        _vm._v(" 3.48%")
      ]),
      _c("span", { staticClass: "text-nowrap" }, [_vm._v("Since last month")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-4 mb-4" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "card card-stats" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "h5",
                  { staticClass: "card-title text-uppercase text-muted mb-0" },
                  [_vm._v(" Réservations /type de chambre ")]
                )
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "card card-stats" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "h5",
                  { staticClass: "card-title text-uppercase text-muted mb-0" },
                  [_vm._v(" Réservations /structure ")]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
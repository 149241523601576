<template>
  <div>
    <h2>Hébergement</h2>

    <div class="row mb-4">
      <div class="col-md-3">
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <label for="">Date</label>
                    <VueCtkDateTimePicker
                      locale="fr"
                      style="display:inline-block;margin-bottom: 20px;"
                      :range="true"
                      color="#34495e"
                      :custom-shortcuts="customDateRangeShortcuts"
                      :only-date="true"
                      :auto-close="false"
                    ></VueCtkDateTimePicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 mt-2">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Arrivées prévue demain
                    </h5>
                    <span class="h2 font-weight-bold mb-0">350</span>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-success mr-2"
                    ><i class="fa fa-arrow-up"></i> 3.48%</span
                  >
                  <span class="text-nowrap">Since last month</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card card-stats mb-4 mb-xl-0 f-h text-center">
          <div class="card-body">
            <div class="row">
              <div class="col br-solid">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Taux d'occupation
                </h5>
                <span class="h2 font-weight-bold mb-0 text-danger">{{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(DashboardData.occuped)  }}%</span>
              </div>
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Taux de disponibilité
                </h5>
                <span class="h2 font-weight-bold mb-0 text-success"
                  >{{(100 - DashboardData.occuped).toPrecision(4)}}%</span
                >
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Total Chambres
                </h5>
                <h4 class="mt-4">{{DashboardData.rooms}}</h4>
              </div>
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Disponibles
                </h5>
                <h4 class="mt-4">356</h4>
              </div>
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Occupées
                </h5>
                <h4 class="mt-4">120</h4>
              </div>
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  En maintenance
                </h5>
                <h4 class="mt-4">2</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="row">
          <div class="col-xl-12 col-lg-12 mt-2">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      ODS En cours
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{DashboardData.inprogress}}</span>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-success mr-2"
                    ><i class="fa fa-arrow-up"></i> 3.48%</span
                  >
                  <span class="text-nowrap">Since last month</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 mt-2">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      ODS Clôturés
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{DashboardData.finished}}</span>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-success mr-2"
                    ><i class="fa fa-arrow-up"></i> 3.48%</span
                  >
                  <span class="text-nowrap">Since last month</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4 mb-4">
      <div class="col-md-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Réservations /type de chambre
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Réservations /structure
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="chart" class="card p-2">
        <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  props:['campid'],
  data() {
    return {
      dates:{},
      DashboardData:[],
      customDateRangeShortcuts: [
        { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
        { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
        { key: "last7Days", label: "7 derniers jours", value: 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label: "Ce mois", value: "month" },
        { key: "lastMonth", label: "Dernier mois", value: "-month" },
        { key: "thisYear", label: "Cette année", value: "year" },
        { key: "lastYear", label: "L'année dernière", value: "-year" },
      ],
      series:[],
        chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'],
            },
            yaxis: {
              title: {
                text: 'Occupation.'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  // return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(val)
                  return val
                }
              }
            }
          },
    };
  },
  mounted(){
      this.fetchCampDashboard();
  },
  methods:{
      fetchCampDashboard() {
        var loader = this.$loading.show();
        this.$http.post("/reporting/campDashboardByType", {
            campId: this.campid,
            type:'hosting',
            dates:this.dates.start ? this.dates : null
        })
        .then((res) => {
            loader.hide();
            console.log(res);
            if(res.data.DashboardData){
              this.DashboardData = res.data.DashboardData;
              this.series = [
                  {name:'Ch.Ordinaire',data:res.data.DashboardData.yearData.breakfasts},
                  {name:'Ch.Missionnaire',data:res.data.DashboardData.yearData.lunchs},
                  {name:'Ch.VIP',data:res.data.DashboardData.yearData.dinners},
                ]
            }
            else this.$toast.error(res.data.msg);
        })
        .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
        })
        .finally(() => {
            loader.hide();
        });
    },
  }
};
</script>

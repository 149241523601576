<template>
  <div class="row">
    <div class="col-xl-4 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">
                Contrats en cours
              </h5>
              <span class="h2 font-weight-bold mb-0">{{dataHeader.contracts}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">
                Nombres de chambres
              </h5>
              <span class="h2 font-weight-bold mb-0">{{dataHeader.rooms}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">
                Nombre d'Employés
              </h5>
              <span class="h2 font-weight-bold mb-0">{{dataHeader.employees}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  props:['dataHeader'],
  // data(){return {info:""};},
  // mounted(){
  //   console.log(this.dataHeader);
  //   this.info = this.dataHeader;
  // }
};
</script>